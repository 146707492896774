.ship-container {
  display: flex;
  flex-direction: column;
}

.ship {
  max-width: 80vw;
  height: auto;
  will-change: transform;
  justify-self: flex-end;
  align-self: center;
}

.ship-info {
  margin: var(--spacing-3);
  align-self: flex-end;
  text-align: right;
}

@keyframes wave {
  to {
    transform: translateY(10%);
  }
}

@media (min-width: 768px) {
  .ship {
    max-width: 40vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .ship {
    animation: wave 2.5s alternate infinite ease-in-out;
  }
}
