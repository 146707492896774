.button {
  padding: var(--spacing-3);
  display: flex;
  align-items: center;
  border: 3px solid #666;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.5);
  font-size: var(--text-lg);
  cursor: pointer;
  background-color: #fff;
  color: var(--dark-color);
}

.sm {
  border-width: 2px;
  padding: var(--spacing-1);
  font-size: var(--text-sm);
}

.button:disabled {
  color: var(--light-grey);
}
