.squad {
  display: none;
}

.list-grid {
  display: grid;
  gap: var(--spacing-5);
  padding: var(--spacing-4) 0;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: 1fr min-content;
  gap: var(--spacing-5);
}

@media (min-width: 728px) {
  .squad {
    display: block;
  }

  .crew {
    grid-template-columns: repeat(3, 1fr);
  }

  .passengers {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .crew {
    grid-template-columns: repeat(4, 1fr);
  }

  .passengers {
    grid-template-columns: repeat(6, 1fr);
  }
}

.crewMember {
  width: 100%;
}

.photo {
  display: block;
  max-width: 100%;
}
