.popper {
  z-index: 1;
}

.tooltip {
  background: #fff;
  color: var(--light-bg);
  font-weight: bold;
  padding: var(--spacing-2);
  font-size: var(--text-sm);
  border-radius: var(--border-radius-sm);
  animation-name: fadeIn;
  animation-duration: .15s;
  animation-timing-function: ease-out;
  cursor: auto;
}

.popper[data-popper-placement^='top'] .tooltip > .arrow {
  bottom: -4px;
}
.popper[data-popper-placement^='bottom'] .tooltip > .arrow {
  top: -4px;
}
.popper[data-popper-placement^='left'] .tooltip > .arrow {
  right: -4px;
}
.popper[data-popper-placement^='right'] .tooltip > .arrow {
  left: -4px;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
