.character-picker {
  padding: var(--spacing-5);

  background-color: rgba(255, 255, 255, .6);
  border-bottom: 1px solid #eee;
  backdrop-filter: blur(10px);
  border-radius: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: var(--spacing-6);
}

.characters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: 1fr min-content;
  gap: var(--spacing-5);
}

.buttons {
  display: flex;
  gap: var(--spacing-3);
  padding: 0 8px 8px 0;
}

@media (min-width: 728px) {
  .characters {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .characters {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1280px) {
  .characters {
    grid-template-columns: repeat(5, 1fr);
  }
}

.character {
  border-radius: .8rem;
  overflow: hidden;
}

.photo {
  display: block;
  max-width: 100%;
  transition: opacity .2s ease-out, filter .2s ease-out;
}

.footer {
  text-align: left;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled {
  opacity: .7;
  filter: grayscale(1);
}

.button {
  display: inline;
  cursor: pointer;
  margin-top: var(--spacing-2);
}