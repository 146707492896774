.empty-slot {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-color);
  border-radius: var(--spacing-2);
  width: 100%;

  min-height: 200px;
}