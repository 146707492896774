.input {
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--border-radius-sm);
  width: 100%;
  font-size: var(--text-md);
}

.input {
  border: 1px solid #ccc;
}
