:root {
  --base-font:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --color: #fff;
  --dark-color: #333;
  --bg-color: #000;
  --light-bg: #fff;
  --light-grey: #888;

  --black: #000;

  --spacing-1: .5rem;
  --spacing-2: .75rem;
  --spacing-3: 1rem;
  --spacing-4: 1.5rem;
  --spacing-5: 2rem;
  --spacing-6: 4rem;
  --spacing-7: 6rem;
  --spacing-8: 8rem;

  --border-radius-sm: .25rem;
  --border-radius-md: .5rem;
  --border-radius-lg: 1rem;
  --border-radius-xl: 2rem;

  --text-sm: .9rem;
  --text-lg: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.4rem;
}
