.card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* in case we use a built-in element with margin eg. figure */
  margin: 0;
  width: 100%;

  border-radius: var(--border-radius-lg);
  box-shadow:
    0 2px 10px 0 rgba(0,0,0,0.1),
    0 4px 6px -2px rgba(0,0,0,0.05);

  background-color: var(--dark-color);
}

.full-height {
  flex-grow: 1;
}

.content {
  padding: var(--spacing-3);
}

.header {
  font-weight: normal;
  margin: 0;
}

.content + .content {
  padding-top: 0;
}
