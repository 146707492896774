@import "./variables";

*,
::before,
::after {
  box-sizing: border-box;
}

html,
body {
  /* classic workaround for issues with 100vh on mobile browsers */
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  line-height: 1.5;
  background-color: var(--bg-color);
}

:global(#stars) {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

:global(#root) {
  position: relative;
  padding: 1rem;
  min-height: 100vh;
}

:global(#root):after {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  bottom: 0;
  background: center repeat url("Assets/noise.png") transparent;
}