.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 2rem;
  max-width: 768px;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    max-width: 2000px;
  }
}